@charset "UTF-8";
/* 宽度对齐 */
figure.highlight table, figure.highlight table > tbody, figure.highlight table > tbody > tr, figure.highlight table > tbody > tr > td {
  display: block;
}

figure.highlight {
  position: relative;
  overflow: auto;
  margin: 20px 0;
  padding: 0;
  border-radius: 8px;
  font-family: Consolas, "Courier New", monospace, -apple-system;
  font-size: 12px;
  font-weight: unset;
  color: #efefef;
  background: #393939;
}
figure.highlight::before {
  content: " ";
  position: absolute;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #fc625d;
  width: 12px;
  height: 12px;
  left: 10px;
  top: 10px;
  -webkit-box-shadow: 20px 0 #fdbc40, 40px 0 #35cd4b;
  box-shadow: 20px 0 #fdbc40, 40px 0 #35cd4b;
}
figure.highlight figcaption {
  position: absolute;
  margin-left: 75px;
  font-size: 18px;
}
figure.highlight pre {
  border: none;
  margin: 0;
  padding: 10px 0;
  color: #efefef;
  background: none;
}
figure.highlight table {
  margin: 30px 0 0 0;
  width: auto;
  border: none;
  border-spacing: unset;
  border-collapse: unset;
}
figure.highlight td {
  border: none;
  padding: 0;
}
figure.highlight .gutter {
  position: absolute;
  width: 40px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
figure.highlight .gutter pre {
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  color: #999999;
  text-align: right;
  background-color: #1b1b1b;
}
figure.highlight .gutter + .code {
  margin-left: 40px;
}
figure.highlight .code {
  overflow: auto;
  background-color: #2d2d2d;
}
figure.highlight .code pre {
  padding-left: 10px;
  padding-right: 15px;
  text-align: left;
  display: inline-block;
}
figure.highlight .code pre ::-webkit-selection {
  color: #515151;
  background: #afe7e7;
}
figure.highlight .code pre ::-moz-selection {
  color: #515151;
  background: #afe7e7;
}
figure.highlight .code pre ::selection {
  color: #515151;
  background: #afe7e7;
}
figure.highlight > button.copy {
  transition: all 0.3s;
  opacity: 0;
  position: absolute;
  color: #efefef;
  background: none;
  right: 5px;
  top: 5px;
  font-size: 12px;
  line-height: 12px;
  padding: 3px 5px;
  border: 1px solid;
  border-radius: 4px;
}
figure.highlight:hover > button.copy {
  opacity: 1;
}
figure.highlight:hover > button.copy:hover {
  color: #66cccc;
}

.gist table {
  width: auto;
}
.gist table td {
  border: none;
}

pre .addition {
  background: #008000;
}
pre .deletion {
  background: #800000;
}
pre .meta {
  color: #cc99cc;
}
pre .comment {
  color: #999999;
}
pre .variable,
pre .attribute,
pre .tag,
pre .regexp,
pre .ruby .constant,
pre .xml .tag .title,
pre .xml .pi,
pre .xml .doctype,
pre .html .doctype,
pre .css .id,
pre .css .class,
pre .css .pseudo {
  color: #f2777a;
}
pre .number,
pre .preprocessor,
pre .built_in,
pre .literal,
pre .params,
pre .constant,
pre .command {
  color: #f99157;
}
pre .ruby .class .title,
pre .css .rules .attribute,
pre .string,
pre .value,
pre .inheritance,
pre .header,
pre .ruby .symbol,
pre .xml .cdata,
pre .special,
pre .number,
pre .formula {
  color: #99cc99;
}
pre .title,
pre .css .hexcolor {
  color: #66cccc;
}
pre .function,
pre .python .decorator,
pre .python .title,
pre .ruby .function .title,
pre .ruby .title .keyword,
pre .perl .sub,
pre .javascript .title,
pre .coffeescript .title {
  color: #6699cc;
}
pre .keyword,
pre .javascript .function {
  color: #cc99cc;
}